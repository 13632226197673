import React from 'react';
// import {
//   DesktopOutlined,
//   SettingOutlined,
//   CustomerServiceOutlined,
//   FileTextOutlined,
//   FileSyncOutlined,
//   DashboardOutlined,
//   TeamOutlined,
//   UserOutlined,
//   CreditCardOutlined,
//   BankOutlined,
// } from "@ant-design/icons";

// export const IconMenu = ({ name }) => {
//   const components = {
//     DesktopOutlined: DesktopOutlined,
//     SettingOutlined: SettingOutlined,
//     CustomerServiceOutlined: CustomerServiceOutlined,
//     FileTextOutlined: FileTextOutlined,
//     FileSyncOutlined: FileSyncOutlined,
//     DashboardOutlined: DashboardOutlined,
//     TeamOutlined: TeamOutlined,
//     UserOutlined: UserOutlined,
//     CreditCardOutlined: CreditCardOutlined,
//     BankOutlined: BankOutlined,
//     Default: DesktopOutlined,
//   };

//   const IconTag = components[name || "Default"] || SettingOutlined;
//   return <IconTag />;
// };

export const routesConfig = [
  // {
  //   path: '/',
  //   component: 'Customer',
  // },

  {
    path: '/',
    component: 'Form',
  },

  // {
  //   path: '/dashboard',
  //   component: 'Dashboard'

  // },

  // {
  //   path: '/student',
  //   component: 'Customer',
  // },

  // {
  //   path: '/approved',
  //   component: 'Approved',
  // },

  // {
  //   path: '/pending',
  //   component: 'Pending',
  // },
  // {
  //   path: '/basic',
  //   component: 'Basics',
  // },
  // {
  //   path: '/amc1',
  //   component: 'amc1',
  // },
  // {
  //   path: '/amc2',
  //   component: 'amc2',
  // },

  // {
  //   path: '/',
  //   component: 'Form',
  // },
  // {
  //   path: '/createForm',
  //   component: 'Quote',
  // },
  // {
  //   path: '/notification',
  //   component: 'Notification',
  // },
  // {
  //   path: '/offers',
  //   component: 'Offers',
  // },
  // {
  //   path: '/studyMaterial',
  //   component: 'studyMaterial',
  // },
  // {
  //   path: '/ib',
  //   component: 'ib',
  // },
  // {
  //   path: '/batch',
  //   component: 'batch',
  // },
  // {
  //   path: '/viewBatch',
  //   component: 'viewBatch',
  // },
  // {
  //   path: '/form',
  //   component: 'PaymentInvoice',
  // },
  // {
  //   path: '/formDataPage',
  //   component: 'FormDataPage',
  // },
  // {
  //   path: '/employee',
  //   component: 'Employee',
  // },
  // {
  //   path: '/admin',
  //   component: 'Admin',
  // },
  // {
  //   path: '/settings',
  //   component: 'Settings/Settings',
  // },

  // {
  // not required for now
  //   path: '/payment/mode',
  //   component: 'PaymentMode',
  // },
  // {
  //   path: '/role',
  //   component: 'Role',
  // },
  // {
  //   path: '/forms',
  //   component: 'Survey',
  // },
  // {

  //   path: "/basic",
  //   component: "basicRegistration"
  // },
  // {
  //   path: "/master",
  //   component: 'masterRegistration'
  // },
  // {
  //   path: "/master2",
  //   component: "masterRegistrationLevel2"

  // },
  // {
  //   path: '/exit',
  //   component: "LogoutPage"
  // }
];
