console.log("process.env.NODE_ENV", process.env.NODE_ENV)
// export const API_BASE_URL =  "http://localhost:8888/api/"
export const API_BASE_URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_DEV_REMOTE === 'remote'
        ? 'https://learning-power.kredallino.tech/api/'
        :  'https://learning-power.kredallino.tech/api/' ;
console.log("API BASE URL", API_BASE_URL)
export const DOWNLOAD_BASE_URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_DEV_REMOTE === 'remote'
        ? 'https://learning-power.kredallino.tech/download'
        : 'http://localhost:8888/download/';
export const STUDENT_LOGIN_URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_DEV_REMOTE === 'remote'
        ? 'https://learning-power.kredallino.tech/studentLogin'
        : 'http://localhost:3000/studentLogin';
export const ACCESS_TOKEN_NAME = 'x-auth-token';